import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { VIEW_ORDERS_VIEW_API, EXPORT_ORDER_UPDATE_STATUS_API } from "assets/api/orderManagementApi";
import PDFDownloader from "components/PdfDownloader";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { toast } from "react-toastify";

const OrderStatusModal = ({ open, onClose, orderDetails }) => {
  //const [data, setData] = useState(null);
  const [openSelect, setOpenSelect] = useState(false);
  const [orderStatusData, setOrderStatusData] = useState(null);

  const handleSelectClose = () => {
    setOpenSelect(false);
  };

  const handleSelectOpen = () => {
    setOpenSelect(true);
  };

 

  const handleDropDownDataChange = async (e) => {
    const name = e.target.name;
    const value = e.target.value;  
   // console.log(name, value);

      if (value) {
        const tokenString = localStorage.getItem("token");
        const userToken = JSON.parse(tokenString);
        const token = userToken?.token;
        const orderData = {_id: orderDetails, [name]: value  };
        if (token) {
          try {
            const apiResponse = await EXPORT_ORDER_UPDATE_STATUS_API(token, orderData);
            
            if (apiResponse?.status === 200) {
              setOrderStatusData(value);
              
            } else {
              toast.error(apiResponse.message);
            }
          } catch (error) {
            // Handle errors that occur during the API call
            console.error("An error occurred:", error);
            // toast.error("An error occurred. Please try again later.");
          }
        }
      }
  };


  

  useEffect(() => {
    const fetchData = async () => {
      if (orderDetails) {
        const tokenString = localStorage.getItem("token");
        const userToken = JSON.parse(tokenString);
        const token = userToken?.token;
        if (token) {
          try {
            const apiResponse = await VIEW_ORDERS_VIEW_API(token, orderDetails);
            if (apiResponse?.status === 200) {
              //setData(apiResponse?.data);
              setOrderStatusData(!apiResponse?.data?.order_progress_status ? 'new' : apiResponse?.data?.order_progress_status);
            } else {
              // Handle API errors, e.g., show an error message
              // toast.error(apiResponse.error);
            }
          } catch (error) {
            // Handle errors that occur during the API call
            console.error("An error occurred:", error);
            // toast.error("An error occurred. Please try again later.");
          }
        }
      }
    };

    fetchData(); // Call the function to fetch data
  }, [orderDetails]);

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="order-details-modal">
      <Box className="modal-container">
        <div className="order-details-content">
      
          <Typography variant="body2"><b>Order Status :</b>
                  <Select
                    name="order_progress_status"
                    open={openSelect}
                    onClose={handleSelectClose}
                    onOpen={handleSelectOpen}
                    label="Order Status"
                    style={{minHeight: "35px", marginLeft: "9px"}}
                    onChange={handleDropDownDataChange}
                    value={orderStatusData}
                  >
                    <MenuItem value="new">New</MenuItem>
                    <MenuItem value="processing">Processing</MenuItem>
                    <MenuItem value="completed">Completed</MenuItem>
                  </Select>

          </Typography>
        </div>
        <Button onClick={onClose} className="close-button">
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default OrderStatusModal;
